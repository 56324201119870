<template>
  <div>
    <Header></Header>
    <div class="content">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, k) in bannerList" :key="k">
          <img :src="oss + item" alt="" class="img_qa" />
        </swiper-slide>
      </swiper>
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3 plr30">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">联系我们</a>
        </div>
      </div>
      <div class="about_page">
        <div class="contact_box m1440">
          <div class="fs26 tc fwb">联系我们</div>
          <div class="map_box swiper">
            <img src="../assets/static/images/image_map1.png" alt="" />
            <div class="address_box">
              <div>中国 上海市 200120</div>
              <div>{{ company_info.officeAddress }}</div>
            </div>
          </div>
          <div class="flex-box flex-between fs18 contacts ">
            <div class="flex-box">电话: +{{ about_us.phone }}</div>
            <div class="flex-box">传真: +{{ about_us.fax }}</div>
            <div class="flex-box">电子邮箱: {{ about_us.email }}</div>
            <div class="flex-box"><a href="https://twh27iaq32.jiandaoyun.com/f/6386889afa0a710007234b8f?ext=etrade" target="_blank">在线留言</a></div>
          </div>
          <div class="fs20 fwb pt40 pb30">更多咨询</div>
          <div class="flex-box flex-between more_contact fs18 flex-col-start">
            <div v-for="(v, index) in about_us.consultJson">
                <div>{{ v.name }}:</div>
                <div>{{ v.value }}</div>
            </div>
             <!-- <div>
                <div><a href="https://twh27iaq32.jiandaoyun.com/f/6386889afa0a710007234b8f?ext=etrade" target="_blank">在线留言</a></div>
                <div> </div> 
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        // loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
        },
      },
      oss: this.global.uploadOss,
      about_us: {},
      company_info:{},
      bannerList:[]
    };
  },

  created() {
    this.get_about();
    this.get_banner()
    this.get_companyInfo()
  },
  methods: {
       //获取banner
       get_banner() {
      let s_data = {
        contentKeys: 'PC_CONTACT'
      }
      this.$api.apiIndexBanner(s_data).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root[0]
          if (data.pictureUrl) {
            data.pictureUrl = data.pictureUrl.split(",")
          }
          this.bannerList = data.pictureUrl
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },
    //获取
    get_about() {
      let s_data = {
        sortType: "DESC",
        deviceType: "1",
      };
      this.$api.apiAboutUs(s_data).then((data_res) => {
        if (data_res.resCode == 0) {
          if (data_res.root) {
            let data = data_res.root[0];
            if (data.consultJson) {
              data.consultJson = JSON.parse(data.consultJson);
            }
            // if (data.bannerPic) {
            //   data.bannerPic = data.bannerPic.split(",");
            // }
            this.about_us = data;
          }
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },

    get_companyInfo() {
    let that = this;
        that.$api.sysCompanyInfo({}).then((data_res) => {
        if (data_res.resCode == 0) {
          if(data_res.root){
            let data = data_res.root[0]; 
             that.company_info = data;
          }
        } else {
          that.$message.error(data_res.resMsg);
        }
      });
    },
  },
};
</script>

<style scoped>
.swiper-container{
  height: 40vh;
}

.swiper-container img{
  object-fit: cover;
  -o-object-fit: cover;
  height: 100%;
}
</style>